import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usersSelector } from '../redux/users/selectors';
import { useAppDispatch } from '../redux/store';
import { selectLounges } from '../redux/lounges/selectors';
import axios from 'axios';
import { GET_BASE_URL, GET_BASE_URL_IMAGE } from "../constants/apiEndpoints";
import { RootState } from '../redux/store';
import StickerFairyLogo from '../assets/img/StickerFairyLogo.jpg';
import dotsGif from '../assets/img/dots2.gif';
import { fetchMyCollection } from '../redux/lounges/slice';
import download from 'downloadjs';
import { BlobServiceClient, ContainerClient, BlobClient, BlockBlobClient, AnonymousCredential } from '@azure/storage-blob';



const StickerFairy = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(usersSelector);
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageToStickerLoading, setIsImageToStickerLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productName, setProductName] = useState('');
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const [transparentImageUrl, setTransparentImageUrl] = useState('');
  const [hasAccess, setHasAccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useAppDispatch();
  const [forceUpdate, setForceUpdate] = useState(0);
  const [addCollectionErrorMessage, setAddCollectionErrorMessage] = useState('');
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [imageToStickerPrompt, setImageToStickerPrompt] = useState('');
  const [imageToStickerUrl, setImageToStickerUrl] = useState('');
  const [imageToStickerThumbnailUrl, setImageToStickerThumbnailUrl] = useState('');
  const [imageToStickerProductName, setImageToStickerProductName] = useState('');
  const [imageToStickerErrorMessage, setImageToStickerErrorMessage] = useState('');
  const [imageToStickerSuccessMessage, setImageToStickerSuccessMessage] = useState('');

  const myCollectionItem = useSelector((state: RootState) => {
    const item = state.lounges.myCollectionItem;
    return Array.isArray(item) ? item[0] : item;
  });

  const creditBalance = myCollectionItem ? (Array.isArray(myCollectionItem) ? myCollectionItem[0]?.credit_balance || 0 : myCollectionItem.credit_balance || 0) : 0;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const club333 = localStorage.getItem('club333');
      const loungeland = localStorage.getItem('loungeland');
      if (!token) {
        navigate('/disneyland/login', { state: { from: '/disneyland/StickerFairy' } });
      } else if (club333 === 'true' || loungeland === 'true') {
        setHasAccess(true);
        try {
          await dispatch(fetchMyCollection());
        } catch (error) {
          console.error('Error fetching my collection:', error);
        }
      }
    };
  
    fetchData();
  }, [navigate, dispatch]);
    

  if (!hasAccess) {
    return (
      <div>
        <h2>StickerFairy is currently for Club 333 only</h2>
        <p>We are working to make it available to all users soon!</p>
      </div>
    );
  }

  const checkCreditBalance = () => {
    if (creditBalance < 10) {
      throw new Error('Insufficient credits. You need at least 10 credits to complete this transaction.');
    }
  };

  const updateCreditBalance = async () => {
    try {
      await dispatch(fetchMyCollection());
      // The state will be updated by the Redux action, so no need to set it here
    } catch (error) {
      console.error('Error updating credit balance:', error);
    }
  };
  
  

  const processStickerGeneration = async () => {
    const token = localStorage.getItem('token');

    try {
      checkCreditBalance();

      const response = await fetch(GET_BASE_URL + "/api/v1/processStickerGen", {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      await updateCreditBalance(); // Return the updated credit balance
  } catch (error) {
    console.error('Error processing sticker generation:', error);
    if (error instanceof Error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('An unexpected error occurred.');
    }
  }
};

  const generateRandomId = () => {
    const randomNumber = Math.floor(Math.random() * 90000) + 10000; // Generate a random number between 10000 and 99999
    return randomNumber.toString(); // Convert the number to a string
  };

  const generateThumbnail = (imageUrl: string, setThumbnailUrl: (url: string) => void) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    if (ctx) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      
      img.onload = () => {
        const thumbnailSize = 150;
        
        canvas.width = thumbnailSize;
        canvas.height = thumbnailSize;
        
        // Enable image smoothing
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        
        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, thumbnailSize, thumbnailSize);
        
        // Convert canvas to data URL at the desired thumbnail size
        const thumbnail = canvas.toDataURL('image/png');
        
        // Set the thumbnail URL state
        setThumbnailUrl(thumbnail);
      };
      
      img.src = imageUrl;
    } else {
      console.error('Failed to get canvas context');
    }
  };
  
  

  const addStickerFairyToCollection = async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchMyCollection()); // Fetch the latest collection to update the credit balance
      const updatedCreditBalance = myCollectionItem ? (Array.isArray(myCollectionItem) ? myCollectionItem[0]?.credit_balance || 0 : myCollectionItem.credit_balance || 0) : 0;
      if (updatedCreditBalance < 10) {
        throw new Error('Insufficient credits. You need at least 10 credits to complete this transaction.');
      }
      const token = localStorage.getItem('token');
      const productId = generateRandomId();
      const response = await axios.post(
        GET_BASE_URL + "/api/v1/addStickerFairytoCollection",
        {
          product_id: productId, 
          product_name: productName, 
          product_image: thumbnailUrl, // Thumbnail image URL
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        console.log('Sticker Fairy added to collection successfully');
        setSuccessMessage('Your Sticker has been added to your collection. To add duplicates, press Add to Collection button above again!');
      } else {
        throw new Error('Failed to add Sticker Fairy to collection');
      }
    } catch (error) {
      console.error('Error adding Sticker Fairy to collection:', error);
      if (error instanceof Error) {
        setAddCollectionErrorMessage(error.message);
      } else {
        setAddCollectionErrorMessage('An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  
  

  const generateImage = async (prompt: string): Promise<{ original: string, transparent: string }> => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const modifiedPrompt = `${prompt}, PIXAR Animation`;
      const response = await axios.post(
        'https://us-west2-wdwdining.cloudfunctions.net/function-1',
        {
          version: '6443cc831f51eb01333f50b757157411d7cadb6215144cc721e3688b70004ad0',
          input: {
            steps: 20,
            width: 512,
            height: 512,
            prompt: modifiedPrompt,
            upscale: true,
            upscale_steps: 10,
            negative_prompt: '',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setIsLoading(false);
  
      const prediction = response.data;
      console.log('Prediction:', prediction);
  
      // Extract the image URLs from the prediction object
      const originalImageUrl = prediction.output[0];
      const transparentImageUrl = prediction.output[1];
  
      console.log('Original Image URL:', originalImageUrl);
      console.log('Transparent Image URL:', transparentImageUrl);
  
      setOriginalImageUrl(originalImageUrl);
      setTransparentImageUrl(transparentImageUrl);
      generateThumbnail(transparentImageUrl, setThumbnailUrl);
  
      return { original: originalImageUrl, transparent: transparentImageUrl };
    } catch (error) {
      setIsLoading(false);
      console.error('Error generating image:', error);
      setErrorMessage('An unexpected error occurred.');
      return { original: '', transparent: '' };
    }
  };
  
  
  const handleGenerateImage = async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchMyCollection());
      // Assuming myCollectionItem is updated by the fetchMyCollection action
      const newCreditBalance = myCollectionItem ? (Array.isArray(myCollectionItem) ? myCollectionItem[0]?.credit_balance || 0 : myCollectionItem.credit_balance || 0) : 0;
      if (newCreditBalance < 10) {
        setErrorMessage('Insufficient credits. You need at least 10 credits to complete this transaction.');
        setIsLoading(false);
        return;
      }
      await processStickerGeneration();
      const { original, transparent } = await generateImage(prompt);
      setOriginalImageUrl(original);
      setTransparentImageUrl(transparent);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to process sticker generation or generate image:', error);
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    }
  };


  const generateImageToSticker = async (uploadedImage: File | null, prompt: string): Promise<{ original: string, transparent: string }> => {
    setIsImageToStickerLoading(true);
    setImageToStickerErrorMessage('');

    try {
      if (!uploadedImage) {
        setIsImageToStickerLoading(false);
        setImageToStickerErrorMessage('No image file selected.');
        return { original: '', transparent: '' };
      }

      const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedFileTypes.includes(uploadedImage.type)) {
        setIsImageToStickerLoading(false);
        setImageToStickerErrorMessage('Unsupported image file type.');
        return { original: '', transparent: '' };
      }

      const modifiedPrompt = `${prompt}`;

      const formData = new FormData();
      formData.append('uploadedImage', uploadedImage, uploadedImage.name);
      formData.append('prompt', modifiedPrompt);

      const response = await axios.post(
        'https://us-central1-wdwdining.cloudfunctions.net/image-sticker',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 150000,
        }
      );

      setIsImageToStickerLoading(false);
      const prediction = response.data;
      console.log('Prediction:', prediction);
      
      // Extract the image URLs from the prediction object
      const originalImageUrl = prediction.output[0];
      const transparentImageUrl = prediction.output[1];

      console.log('Original Image URL:', originalImageUrl);
      console.log('Transparent Image URL:', transparentImageUrl);

      setImageToStickerUrl(transparentImageUrl);
      generateThumbnail(transparentImageUrl, setImageToStickerThumbnailUrl);

      return { original: originalImageUrl, transparent: transparentImageUrl };
    } catch (error) {
      setIsImageToStickerLoading(false);
      console.error('Error generating image to sticker:', error);
      setImageToStickerErrorMessage('An unexpected error occurred.');
      return { original: '', transparent: '' };
    }
  };


const handleGenerateImageToSticker = async () => {
  if (!uploadedImage) {
    setImageToStickerErrorMessage('Please select an image file.');
    return;
  }

  try {
    setIsImageToStickerLoading(true);
    await dispatch(fetchMyCollection());
    // Assuming myCollectionItem is updated by the fetchMyCollection action
    const newCreditBalance = myCollectionItem ? (Array.isArray(myCollectionItem) ? myCollectionItem[0]?.credit_balance || 0 : myCollectionItem.credit_balance || 0) : 0;
    if (newCreditBalance < 20) {
      setImageToStickerErrorMessage('Insufficient credits. You need at least 20 credits to complete this transaction.');
      setIsImageToStickerLoading(false);
      return;
    }
    await processStickerGeneration();
    await processStickerGeneration(); // Deduct an additional 10 credits for a total of 20 credits
    const { original, transparent } = await generateImageToSticker(uploadedImage, imageToStickerPrompt);
    setImageToStickerUrl(transparent);
    setOriginalImageUrl(original);
    setIsImageToStickerLoading(false);
  } catch (error) {
    setIsImageToStickerLoading(false);
    console.error('Failed to process image to sticker generation:', error);
    if (error instanceof Error) {
      setImageToStickerErrorMessage(error.message);
    } else {
      setImageToStickerErrorMessage('An unexpected error occurred.');
    }
  }
};

const addImageToStickerToCollection = async () => {
  try {
    setIsLoading(true);
    await dispatch(fetchMyCollection()); // Fetch the latest collection to update the credit balance
    const updatedCreditBalance = myCollectionItem ? (Array.isArray(myCollectionItem) ? myCollectionItem[0]?.credit_balance || 0 : myCollectionItem.credit_balance || 0) : 0;
    if (updatedCreditBalance < 10) {
      throw new Error('Insufficient credits. You need at least 10 credits to complete this transaction.');
    }
    const token = localStorage.getItem('token');
    const productId = generateRandomId();
    const response = await axios.post(
      GET_BASE_URL + "/api/v1/addStickerFairytoCollection",
      {
        product_id: productId,
        product_name: imageToStickerProductName,
        product_image: imageToStickerThumbnailUrl,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      console.log('Image to Sticker added to collection successfully');
      setImageToStickerSuccessMessage('Your Sticker has been added to your collection. To add duplicates, press Add to Collection button above again!');
    } else {
      throw new Error('Failed to add Image to Sticker to collection');
    }
  } catch (error) {
    console.error('Error adding Image to Sticker to collection:', error);
    if (error instanceof Error) {
      setImageToStickerErrorMessage(error.message);
    } else {
      setImageToStickerErrorMessage('An unexpected error occurred.');
    }
  } finally {
    setIsLoading(false);
  }
};


return (
  <div>
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden' }}>
      <img src={StickerFairyLogo} alt="Sticker Fairy" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
      <input
        type="text"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe your Sticker (e.g. Mickey Mouse as a baby, Disneyland)"
        style={{ width: 'calc(100% - 40px)', padding: '20px', fontSize: '20px', boxSizing: 'border-box' }}
      />
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', padding: '1px 0' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
        <button onClick={handleGenerateImage} disabled={isLoading} style={{ width: '100%', maxWidth: '250px' }}>
          Generate Sticker (10 Credits)
        </button>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {isLoading && (
          <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden' }}>
            <img src={dotsGif} alt="Loading..." style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        )}
        {!isLoading && (
          <>
            {originalImageUrl && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <a href={originalImageUrl} download="original_sticker.png">
                  <img
                    src={originalImageUrl}
                    alt="Original Sticker"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </a>
              </div>
            )}
            {transparentImageUrl && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <a href={transparentImageUrl} download="transparent_sticker.png">
                  <img
                    src={transparentImageUrl}
                    alt="Transparent Sticker"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </a>
              </div>
            )}
            {thumbnailUrl && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <a href={thumbnailUrl} download="thumbnail_sticker.png">
                  <img
                    src={thumbnailUrl}
                    alt="Thumbnail"
                    width="150"
                    height="150"
                  />
                </a>
              </div>
            )}
            {transparentImageUrl && (
              <>
                <input
                  type="text"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Name your image"
                  style={{ width: 'calc(100% - 40px)', maxWidth: '200px' }}
                />
                <button
                  onClick={() => {
                    addStickerFairyToCollection().catch((error) => {
                      console.error('Error adding Sticker Fairy to collection:', error);
                      if (error instanceof Error) {
                        setAddCollectionErrorMessage(error.message);
                      } else {
                        setAddCollectionErrorMessage('An unexpected error occurred.');
                      }
                    });
                  }}
                  style={{ width: 'calc(100% - 40px)', maxWidth: '200px' }}
                >
                  Add to Collection (10 Credits each)
                </button>
                {addCollectionErrorMessage && <p style={{ color: 'red' }}>{addCollectionErrorMessage}</p>}
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              </>
            )}
          </>
        )}
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ fontWeight: 'bold', textAlign: 'center' }}>NEW! Turn yourself into a Disney character or ANY character!</h2>
      <p style={{ textAlign: 'center', marginBottom: '10px' }}>For best results, upload a clear headshot. Then, add your prompt (e.g. Marvel superhero, Disney character, etc.)</p>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                setUploadedImage(e.target.files[0]);
              }
            }}
            style={{ textAlign: 'center' }}
          />
          {uploadedImage && <span style={{ textAlign: 'center', display: 'block' }}>{uploadedImage.name}</span>}
        </div>
        <input
          type="text"
          value={imageToStickerPrompt}
          onChange={(e) => setImageToStickerPrompt(e.target.value)}
          placeholder="Describe who you want to be"
          style={{ width: 'calc(100%)', padding: '20px', fontSize: '20px', boxSizing: 'border-box' }}
        />
        <button
          onClick={handleGenerateImageToSticker}
          disabled={isImageToStickerLoading}
          style={{ width: '100%', maxWidth: '250px' }}
        >
          Generate Image to Sticker (20 Credits)
        </button>
        {imageToStickerErrorMessage && <p style={{ color: 'red' }}>{imageToStickerErrorMessage}</p>}
        {isImageToStickerLoading && (
          <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden' }}>
            <img src={dotsGif} alt="Loading..." style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        )}
        {!isImageToStickerLoading && (
        <>
          {imageToStickerUrl && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <a href={imageToStickerUrl} download="sticker_upload.png">
                <img
                  src={imageToStickerUrl}
                  alt="Sticker Upload"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </a>
            </div>
          )}
          {originalImageUrl && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <a href={originalImageUrl} download="original_sticker.png">
                <img
                  src={originalImageUrl}
                  alt="Original Sticker"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </a>
            </div>
          )}
          {imageToStickerThumbnailUrl && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <a href={imageToStickerThumbnailUrl} download="thumbnail_sticker.png">
                <img
                  src={imageToStickerThumbnailUrl}
                  alt="Thumbnail"
                  width="150"
                  height="150"
                />
              </a>
            </div>
          )}
          {imageToStickerUrl && (
            <>
              <input
                type="text"
                value={imageToStickerProductName}
                onChange={(e) => setImageToStickerProductName(e.target.value)}
                placeholder="Name your image"
                style={{ width: 'calc(100% - 40px)', maxWidth: '200px' }}
              />
              <button
                onClick={() => {
                  addImageToStickerToCollection().catch((error) => {
                    console.error('Error adding Image to Sticker to collection:', error);
                    if (error instanceof Error) {
                      setImageToStickerErrorMessage(error.message);
                    } else {
                      setImageToStickerErrorMessage('An unexpected error occurred.');
                    }
                  });
                }}
                style={{ width: 'calc(100% - 40px)', maxWidth: '200px' }}
              >
                Add to Collection (10 Credits each)
              </button>
              {imageToStickerErrorMessage && <p style={{ color: 'red' }}>{imageToStickerErrorMessage}</p>}
              {imageToStickerSuccessMessage && <p style={{ color: 'green' }}>{imageToStickerSuccessMessage}</p>}
            </>
          )}
        </>
      )}
    </div>
    </div>
    <div style={{ paddingBottom: '20px' }}></div>
  </div>
);
}

const handleDownloadImage = (imageUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = fileName;
  link.click();
};

const handleImageClick = async (imageUrl: string) => {
  if (imageUrl) {
    const confirmSave = window.confirm('Do you want to save this image to your device?');
    if (confirmSave) {
      if (navigator.share) {
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'sticker.png', { type: 'image/png' });
          await navigator.share({
            files: [file],
            title: 'Sticker',
            text: 'Save this sticker to your device',
          });
        } catch (error) {
          console.error('Error sharing image:', error);
          fallbackDownload(imageUrl);
        }
      } else {
        fallbackDownload(imageUrl);
      }
    }
  }
};

const fallbackDownload = (imageUrl: string) => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = 'sticker.png';
  link.click();
};

const handleSaveImage = async (imageUrl: string) => {
  try {
    const response = await axios.get(imageUrl, { responseType: 'blob' });
    download(response.data, 'sticker.png', 'image/png');
  } catch (error) {
    console.error('Error saving image:', error);
  }
};



export default StickerFairy;